import React from 'react';
import Loadable from 'react-loadable';
import {
  ComponentPreview,
  DontDo,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
  SectionSubhead,
} from '../../../components';
import videoplayerPreviewData from '../../../data/previews/video-player.yml';
import pageHeroData from '../../../data/pages/components.yml';
import styles from './styles/videoPlayer.module.scss';

const IconPlay = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconPlay;
    return <Component {...props} />;
  },
});

const IconPause = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconPause;
    return <Component {...props} />;
  },
});

const IconEnterFullscreen = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconEnterFullscreen;
    return <Component {...props} />;
  },
});

const IconExitFullscreen = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconExitFullscreen;
    return <Component {...props} />;
  },
});

const IconAudio = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconAudio;
    return <Component {...props} />;
  },
});

const IconAudioOff = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconAudioOff;
    return <Component {...props} />;
  },
});

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      playState: 'paused',
      volumeState: 'off',
      fullscreenState: 'exit',
      videoDuration: '0:00',
      videoProgress: '0:00',
      videoProgressBar: '0%',
      elementSelection: '',
    };
  }

  handleElementChange(value) {
    this.setState({
      elementSelection: value,
    });
  }

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Video Player"
        designCode
        subnav="components">
        <PageHero heroData={pageHeroData} tierTwo="Video Player" />

        <PageNavigation
          links={['Architecture', 'Controls', 'Usage', 'Platform']}
        />

        <Section title="Architecture">
          <Paragraph>
            The video player is made up of many parts to allow flexibility for
            the purpose it's fulfilling. That purpose could be strictly to allow
            coaches to draw over video or to simply watch the latest game.
          </Paragraph>
          <ComponentPreview
            name="Architecture"
            layout="split"
            onChange={this.handleElementChange.bind(this)}
            previewData={videoplayerPreviewData.architecture}>
            <div
              className={
                'uni-environment--dark ' +
                styles.videoWrapper +
                ' ' +
                this.state.elementSelection
              }>
              <video
                id="video"
                muted
                className={styles.videoVideo}
                onLoadedMetadata={e => {
                  this.setState({
                    videoDuration: `0:${Math.round(
                      e.target.duration.toFixed(2)
                    )}`,
                  });
                }}
                onTimeUpdate={e => {
                  let time = Math.round(e.target.currentTime.toFixed(2));
                  time = time < 10 ? `0${time}` : time;

                  this.setState({
                    videoProgress: `0:${time}`,
                    videoProgressBar:
                      Math.floor(
                        (e.target.currentTime / e.target.duration) * 100
                      ) + '%',
                  });
                }}>
                <source
                  src="https://static.hudl.com/uniform/video-player-patrick-mahomes.mp4"
                  type="video/mp4"
                />
              </video>
              <div className={styles.videoContainer}>
                <div className={styles.videoProgress}>
                  <div
                    className={styles.videoProgressHead}
                    style={{ width: this.state.videoProgressBar }}
                  />
                </div>
                <div className={styles.videoLeft}>
                  {this.state.playState === 'paused' && (
                    <div
                      className={styles.videoPlay}
                      onClick={() => {
                        document.getElementById('video').play();
                        this.setState({ playState: 'playing' });
                      }}>
                      <IconPlay />
                    </div>
                  )}
                  {this.state.playState === 'playing' && (
                    <div
                      className={styles.videoPlay}
                      onClick={() => {
                        document.getElementById('video').pause();
                        this.setState({ playState: 'paused' });
                      }}>
                      <IconPause />
                    </div>
                  )}

                  <div className={styles.videoTime}>
                    {this.state.videoProgress} / {this.state.videoDuration}
                  </div>
                </div>
                <div className={styles.videoRight}>
                  {this.state.volumeState === 'on' && (
                    <div
                      onClick={() => {
                        document.getElementById('video').muted = true;
                        this.setState({ volumeState: 'off' });
                      }}>
                      <IconAudio />
                    </div>
                  )}
                  {this.state.volumeState === 'off' && (
                    <div
                      onClick={() => {
                        document.getElementById('video').muted = false;
                        this.setState({ volumeState: 'on' });
                      }}>
                      <IconAudioOff />
                    </div>
                  )}
                  {this.state.fullscreenState === 'exit' && (
                    <div
                      onClick={() => {
                        const videoContainer = document.getElementById('video');
                        if (videoContainer.requestFullscreen) {
                          videoContainer.requestFullscreen();
                        } else if (videoContainer.mozRequestFullScreen) {
                          videoContainer.mozRequestFullScreen();
                        } else if (videoContainer.webkitRequestFullScreen) {
                          videoContainer.webkitRequestFullScreen();
                        } else if (videoContainer.msRequestFullscreen) {
                          videoContainer.msRequestFullscreen();
                        }
                        this.setState({ fullscreenState: 'exit' });
                      }}
                      className={styles.videoFullscreen}>
                      <IconEnterFullscreen />
                    </div>
                  )}
                  {this.state.fullscreenState === 'enter' && (
                    <div
                      onClick={() => {
                        if (document.exitFullscreen) document.exitFullscreen();
                        else if (document.mozCancelFullScreen) {
                          document.mozCancelFullScreen();
                        } else if (document.webkitCancelFullScreen) {
                          document.webkitCancelFullScreen();
                        } else if (document.msExitFullscreen) {
                          document.msExitFullscreen();
                        }
                        this.setState({ fullscreenState: 'enter' });
                      }}
                      className={styles.videoFullscreen}>
                      <IconExitFullscreen />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </ComponentPreview>
        </Section>
        <Section title="Controls">
          <Paragraph>
            The video player controls are made up mostly of{' '}
            <Link href="/components/icons/media-playback/design">
              media playback
            </Link>{' '}
            icons. However, over time we've added new{' '}
            <Link href="/components/icons/features-and-navigation/design">
              features
            </Link>{' '}
            and <Link href="/components/icons/effects/design">effects</Link> to
            enhance video for our users.
          </Paragraph>
        </Section>

        <Section title="Usage">
          <SectionSubhead>Playback Controls</SectionSubhead>
          <Paragraph>
            The playback controls are the most important way to manipulate video
            in the way the user chooses. We should never put them in an
            unexpected place.
          </Paragraph>
          <DontDo
            dontText=" replace the positioning for standard playback controls."
            doText="make new functions accessible from the control bar."
            imgFilename="video-player-playback-controls"
          />

          <SectionSubhead>Functions</SectionSubhead>
          <Paragraph>
            We do more than just play back video. When features or functions
            become available, or are removed, make them appear predictably,
            without overwhelming our users with everything at once.
          </Paragraph>
          <DontDo
            dontText="display controls that are unvailable in the user's context."
            doText="provide the appropriate controls for the audience."
            imgFilename="video-player-type-controls"
          />

          <DontDo
            dontText="overwhelm the interface by displaiyng all possible functions at once."
            doText="progressively disclose like functions together."
            imgFilename="video-player-overwhelm-controls"
          />
        </Section>

        <Section title="Platform">
          <PlatformTable />
        </Section>
      </PageWithSubNav>
    );
  }
}
